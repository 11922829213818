import { TStatus } from '../../types';

export interface ISchedulers {
  schedulerId: string;
}

export enum ECronPossibleTime {
  EVERY_HOUR = 'EVERY_HOUR',
  EVERY_30_MINUTES = 'EVERY_30_MINUTES',
  EVERY_5_MINUTES = 'EVERY_5_MINUTES',
  EVERY_24_HOURS = 'EVERY_24_HOURS',
}
export interface ISchedulerCreate {
  name: string;
  cronExpression: ECronPossibleTime;
}

export interface IStoreScheduler extends ISchedulerCreate {
  list: ISchedulers[];
  refetch: boolean;
  status: TStatus;
}
