import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import * as RR from 'react-router-dom';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import { EmailPasswordComponentsOverrideProvider } from 'supertokens-auth-react/recipe/emailpassword';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import CronJobs from './components/CronJobs';
import CancelFRB from './components/FRB/CancelFRB';
import CreateFRB from './components/FRB/CreateFRB';
import { CancelButton } from './components/LoginPage/CancelBtn';
import Template from './components/Template';
import Wallet from './components/Wallet';
import { PreBuiltUIList, SuperTokensConfig } from './config';
import i18n from './i18next';
import Game from './pages/Game';
import Home from './pages/Home';
import Play from './pages/Play';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Tournament from './pages/Tournament';

SuperTokens.init(SuperTokensConfig);

i18n.on('languageChanged', (lng) => SuperTokens.changeLanguage(lng));
i18n.on('loaded', () => SuperTokens.loadTranslation({}));

function App() {
  return (
    <SuperTokensWrapper>
      <EmailPasswordComponentsOverrideProvider
        components={{
          EmailPasswordSignIn_Override: ({ DefaultComponent, ...props }) => {
            return (
              <Template>
                <DefaultComponent {...props} />
                <CancelButton />
              </Template>
            );
          },
          EmailPasswordSignUp_Override: ({ DefaultComponent, ...props }) => {
            return (
              <Template>
                <DefaultComponent {...props} />
                <CancelButton />
              </Template>
            );
          },
        }}
      >
        <Router>
          <Routes>
            {getSuperTokensRoutesForReactRouterDom(RR, PreBuiltUIList)}
            <Route
              path="/"
              element={
                <Template>
                  <Home />
                </Template>
              }
            />
            <Route
              path="/profile"
              element={
                <SessionAuth doRedirection={true}>
                  <Template>
                    <Profile />
                  </Template>
                </SessionAuth>
              }
            />
            <Route
              path="/settings"
              element={
                <SessionAuth doRedirection={true}>
                  <Template>
                    <Settings />
                  </Template>
                </SessionAuth>
              }
            />
            <Route
              path="/wallet"
              element={
                <SessionAuth doRedirection={true}>
                  <Template>
                    <Wallet />
                  </Template>
                </SessionAuth>
              }
            />
            <Route
              path="/create-frb"
              element={
                <SessionAuth doRedirection={true}>
                  <Template>
                    <CreateFRB />
                  </Template>
                </SessionAuth>
              }
            />
            <Route
              path="/scheduler"
              element={
                <SessionAuth doRedirection={true}>
                  <Template>
                    <CronJobs />
                  </Template>
                </SessionAuth>
              }
            />
            <Route
              path="/cancel-frb"
              element={
                <SessionAuth doRedirection={true}>
                  <Template>
                    <CancelFRB />
                  </Template>
                </SessionAuth>
              }
            />
            <Route
              path="/tournament"
              element={
                <Template>
                  <Tournament />
                </Template>
              }
            />
            <Route
              path="/play"
              element={
                <Template>
                  <Play />
                </Template>
              }
            />
            <Route
              path="/play/:gameId"
              element={
                <SessionAuth doRedirection={true}>
                  <Template>
                    <Game demo={false} />
                  </Template>
                </SessionAuth>
              }
            />
            <Route
              path="/demo/:gameId"
              element={
                <Template>
                  <Game demo={true} />
                </Template>
              }
            />
          </Routes>
        </Router>
      </EmailPasswordComponentsOverrideProvider>
    </SuperTokensWrapper>
  );
}

export default App;
