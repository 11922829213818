import React from 'react';
import { useTranslation } from 'react-i18next';
import { CRYPTO_CURRENCY, CURRENCY, PLAYER_CONFIG, sortConfig } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../store';
import { changeAmount, fetchWalletMainCurrency, fetchWalletRefill, fetchWallets } from './store';
import { IWallet } from './types';

function Wallet() {
  const dispatch = useAppDispatch();

  const { wallets, currency } = useAppSelector((state) => {
    return {
      wallets: state.wallets.wallets,
      currency: state.player.currency,
    };
  });

  const { t, i18n } = useTranslation();
  const [lng, setLng] = React.useState(i18n.language.toLocaleUpperCase());

  const currencyList = React.useMemo(() => {
    return Array.from(
      new Set(
        CRYPTO_CURRENCY.concat(CURRENCY).map((val) => {
          return val;
        }),
      ),
    ).sort((a, b) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }, []);

  React.useEffect(() => {
    dispatch(fetchWallets());
  }, [currency]);

  const onChangeCurrency = React.useCallback((currency: string) => {
    dispatch(fetchWalletMainCurrency(currency));
  }, []);

  const onChangeRefill = React.useCallback(({ amount, type, currency }: IWallet) => {
    if (+amount >= 0) {
      dispatch(fetchWalletRefill({ amount: +amount, type, currency }));
    }
  }, []);

  const onChangeAmount = React.useCallback((amount: string, index: number) => {
    dispatch(changeAmount({ amount, index }));
  }, []);

  return (
    <div className="container">
      <form className="row g-3">
        <div className="mb-8 col-md-8 offset-md-2">
          <label className="form-label">{t('labelCountry')}</label>
          <select
            value={lng}
            className="form-select"
            name="country"
            onChange={(e) => {
              i18n.changeLanguage(e.target.value);
              setLng(e.target.value);
            }}
          >
            {PLAYER_CONFIG.sort(sortConfig).map((val) => {
              return (
                <option key={val.country} value={val.code}>
                  {val.country} ({val.code})
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-8 col-md-8 offset-md-2">
          <label className="form-label">{t('labelCurrency')}</label>
          <select
            value={currency}
            className="form-select"
            name="currency"
            onChange={(e) => {
              onChangeCurrency(e.target.value);
            }}
          >
            {currencyList.map((val) => {
              return (
                <option key={val} value={val}>
                  {CRYPTO_CURRENCY.includes(val) ? `${val}_crypto` : val}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-8 col-md-8 offset-md-2">
          <label className="form-label">{t('labelWallets')}</label>
          <table className="table table-dark table-borderless table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Currency</th>
                <th scope="col">type</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              {wallets.map((w, i) => (
                <tr className={w.currency === currency ? 'table-primary' : ''} key={i}>
                  <th scope="row">{i + 1}</th>
                  <td>{w.currency}</td>
                  <td>{w.type}</td>
                  <td>
                    <div className="input-group input-group-sm">
                      <input
                        type="number"
                        className="form-control"
                        value={w.amount}
                        onChange={(e) => onChangeAmount(e.target.value, i)}
                      />
                      <button onClick={() => onChangeRefill(w)} className="btn btn-outline-secondary" type="button">
                        {t('refill')}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}

export default Wallet;
