import React from 'react';
import { Link, NavLink } from 'react-router-dom';

function Settings() {
  return (
    <div className="container">
      <div className="btn-toolbar btn-group">
        <NavLink className="h1 btn btn-success me-2" to="/wallet">
          Wallet
        </NavLink>
        <Link className="h1 btn btn-success me-2" to="/create-frb">
          FRB create
        </Link>
        <Link className="h1 btn btn-success me-2" to="/cancel-frb">
          FRB cancel
        </Link>
        <Link className="h1 btn btn-success me-2" to="/scheduler">
          Cron jobs
        </Link>
      </div>
    </div>
  );
}

export default Settings;
