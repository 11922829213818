import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  changeCronExpression,
  changeName,
  changeRefetch,
  fetchSchedulerCreate,
  fetchSchedulerRemove,
  fetchSchedulers,
} from './store';
import { ECronPossibleTime } from './type';

export const CronPossibleTime: ECronPossibleTime[] = [
  ECronPossibleTime.EVERY_HOUR,
  ECronPossibleTime.EVERY_30_MINUTES,
  ECronPossibleTime.EVERY_5_MINUTES,
  ECronPossibleTime.EVERY_24_HOURS,
];

function CronJobs() {
  const dispatch = useAppDispatch();

  const { name, cronExpression, refetch, list, email } = useAppSelector((state) => {
    return {
      ...state.scheduler,
      email: state.player.email,
    };
  });

  const onChangeCronExpression = useCallback((v: ECronPossibleTime) => {
    dispatch(changeCronExpression(v));
  }, []);

  const onChangeName = useCallback((v: string) => {
    dispatch(changeName(v));
  }, []);

  const onRemove = useCallback((id: string) => {
    dispatch(fetchSchedulerRemove(id));
  }, []);

  useEffect(() => {
    dispatch(fetchSchedulers());
  }, []);

  useEffect(() => {
    if (refetch) {
      dispatch(fetchSchedulers());
      dispatch(changeRefetch());
    }
  }, [refetch]);

  const schedulerCreate = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(fetchSchedulerCreate({ name, cronExpression }));
    },
    [name, cronExpression],
  );

  return (
    <div className="container">
      <form className="row g-3">
        <div className="mb-8 col-md-8 offset-md-2">
          <label className="form-label">cron expression</label>
          <select
            value={cronExpression}
            className="form-select"
            name="cronExpression"
            onChange={(e) => {
              onChangeCronExpression(e.target.value as ECronPossibleTime);
            }}
          >
            {CronPossibleTime.map((val) => {
              return (
                <option key={val} value={val}>
                  {val}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-8 col-md-8 offset-md-2">
          <label className="form-label">name</label>
          <input
            value={name ?? ''}
            onChange={(e) => onChangeName(e.target.value)}
            type="string"
            className="form-control"
          />
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary" onClick={(e) => schedulerCreate(e)}>
            create JOB
          </button>
        </div>
        <div className="mb-8 col-md-8 offset-md-2">
          <label className="form-label">scheduler list</label>
          <table className="table table-dark table-borderless table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">ID</th>
                <th scope="col">remove</th>
              </tr>
            </thead>
            <tbody>
              {list.map((s, i) => (
                <tr className={s.schedulerId.includes(email) ? 'table-primary' : ''} key={i}>
                  <th scope="row">{i + 1}</th>
                  <td>{s.schedulerId}</td>
                  <td>
                    <div className="input-group input-group-sm">
                      <button
                        onClick={() => onRemove(s.schedulerId)}
                        className="btn btn-outline-secondary"
                        type="button"
                      >
                        remove
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}

export default CronJobs;
