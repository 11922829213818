import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../lib/fetch';
import { TBody } from '../../lib/fetch/types';
import { ECronPossibleTime, ISchedulerCreate, ISchedulers, IStoreScheduler } from './type';

export const fetchSchedulers = createAsyncThunk('scheduler/list', async () => {
  const url = '/api/scheduler/list';
  const schedulers = await httpClient.get<ISchedulers[]>(url);
  return schedulers;
});

export const fetchSchedulerCreate = createAsyncThunk('/scheduler/create', async (data: ISchedulerCreate) => {
  const url = '/api/scheduler/create';
  await httpClient.post<boolean>(url, {
    ...data,
  });
});

export const fetchSchedulerRemove = createAsyncThunk('scheduler/remove', async (schedulerId: string) => {
  const url = '/api/scheduler/remove';
  await httpClient.post<boolean>(url, { schedulerId } as unknown as TBody);
});

const initialState: IStoreScheduler = {
  list: [],
  refetch: false,
  status: 'idle',
  name: '',
  cronExpression: ECronPossibleTime.EVERY_24_HOURS,
};

const schedulerSlice = createSlice({
  name: 'scheduler',
  initialState,
  reducers: {
    changeName(state, { payload }: PayloadAction<string>) {
      return {
        ...state,
        name: payload,
      };
    },
    changeCronExpression(state, { payload }: PayloadAction<ECronPossibleTime>) {
      return {
        ...state,
        cronExpression: payload,
      };
    },
    changeRefetch(state) {
      return {
        ...state,
        refetch: false,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSchedulers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSchedulers.fulfilled, (state, action) => {
        return {
          ...state,
          status: 'succeeded',
          list: action.payload,
        };
      })
      .addCase(fetchSchedulers.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSchedulerCreate.fulfilled, (state) => {
        return {
          ...state,
          refetch: true,
          name: '',
        };
      })
      .addCase(fetchSchedulerRemove.fulfilled, (state) => {
        return {
          ...state,
          refetch: true,
        };
      });
  },
});

export const { changeName, changeCronExpression, changeRefetch } = schedulerSlice.actions;

export default schedulerSlice.reducer;
