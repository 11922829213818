import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  changeCalculatePayment,
  changeProhibitClosure,
  fetchCancelFRB,
  fetchInfoFRB,
  fetchListCreatedFRB,
  fetchListFRB,
  fetchRemoveFRB,
} from './store';

function CancelFRB() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { list, createdList, calculatePayment, prohibitClosure, currency } = useAppSelector((state) => {
    return {
      createdList: state.frb.createdList,
      list: state.frb.frbList,
      calculatePayment: state.frb.calculatePayment,
      prohibitClosure: state.frb.prohibitClosure,
      status: state.frb.status,
      currency: state.player.currency,
    };
  });

  React.useEffect(() => {
    dispatch(fetchListCreatedFRB());
  }, []);

  React.useEffect(() => {
    if (currency && !list.length) {
      dispatch(fetchListFRB(currency));
    }
  }, [currency, list.length]);

  const slotName = React.useCallback(
    (id: string) => {
      const slot = list.find((i) => i.gameId === id);
      if (slot) {
        return slot.gameName;
      }
      return id;
    },
    [list.length],
  );

  const onChangeCalculatePayment = React.useCallback(() => {
    dispatch(changeCalculatePayment());
  }, []);

  const onChangeProhibitClosure = React.useCallback(() => {
    dispatch(changeProhibitClosure());
  }, []);

  const onClickCancelFRB = React.useCallback(
    (id: string) => {
      dispatch(fetchCancelFRB({ id, calculatePayment, prohibitClosure }));
    },
    [calculatePayment, prohibitClosure],
  );

  const onClickInfoFRB = React.useCallback((id: string) => {
    dispatch(fetchInfoFRB(id));
  }, []);

  const onClickRemoveFRB = React.useCallback((id: string) => {
    dispatch(fetchRemoveFRB(id));
  }, []);

  return (
    <div className="mb-8 col-md-8 offset-md-2">
      <div className="input-group input-inline">
        <>
          <div className="input-group-text">
            <input
              className="form-check-input"
              type="checkbox"
              checked={calculatePayment}
              aria-label="Checkbox for following text input"
              onChange={onChangeCalculatePayment}
            />
          </div>
          <span className="input-group-text">Close FRB with payout</span>
          <div className="input-group-text">
            <input
              className="form-check-input"
              type="checkbox"
              checked={prohibitClosure}
              aria-label="Checkbox for following text input"
              onChange={onChangeProhibitClosure}
            />
          </div>
          <span className="input-group-text">Prohibit closing active FRB</span>
        </>
      </div>
      <table className="table table-dark table-borderless table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">name</th>
            <th scope="col">rounds</th>
            <th scope="col">roundsPlayed</th>
            <th scope="col">status</th>
            <th scope="col">realAmount</th>
            <th scope="col">coinAmount</th>
            <th scope="col">coinValue</th>
            <th scope="col">startTime</th>
            <th scope="col">expiredAt</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {createdList.map((w, i) => (
            <tr key={i}>
              <th scope="row">{i + 1}</th>
              <td>{slotName(w.gameId)}</td>
              <td>{w.rounds}</td>
              <td>{w.roundsPlayed}</td>
              <td>{w.status}</td>
              <td>{w.realAmount || w.realCoinAmount}</td>
              <td>{w.coinAmount}</td>
              <td>{w.coinValue}</td>
              <td>{w.startTime}</td>
              <td>{w.expiredAt}</td>
              <td>
                <div className="input-group input-group-sm">
                  <button
                    onClick={() => onClickInfoFRB(w.externalBonusId)}
                    className="btn btn-outline-info"
                    type="button"
                  >
                    {t('info')}
                  </button>
                  <button
                    onClick={() => onClickCancelFRB(w.externalBonusId)}
                    className="btn btn-outline-secondary"
                    type="button"
                  >
                    {t('cancel')}
                  </button>
                  <button
                    onClick={() => onClickRemoveFRB(w.externalBonusId)}
                    className="btn btn-outline-warning"
                    type="button"
                  >
                    {t('remove')}
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CancelFRB;
